export const dataActive = [
	{
		title: 'Active',
		key: 1,
		value: 1,
	},
	{
		title: 'None',
		key: 2,
		value: 0,
	},
	{
		title: 'All',
		key: 3,
		value: '',
	},
];
