import React, { useEffect } from 'react';
import logo from './logo.svg';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { Routes, Route, BrowserRouter, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Authenticate from './pages/authenticate/Authenticate';
import Register from './pages/register/Register';
import ResetPass from './pages/reset/ResetPass';
import Home from './pages/home/Home';
import ListUser from './pages/list-user/ListUser';
import ChangePassword from './pages/change-password/ChangePassword';
import NotFountPage from './pages/404';

import PrivateRoute from './routes/PrivateRoute';
import './App.css';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<Navigate to="/authenticate" />} />
				<Route path="/authenticate" element={<Authenticate />} />
				<Route path="/register" element={<Register />} />
				<Route path="/reset-password" element={<ResetPass />} />
				<Route
					path="/change-password"
					element={
						<PrivateRoute>
							<ChangePassword />
						</PrivateRoute>
					}
				/>
				<Route
					path="/"
					element={
						<PrivateRoute>
							<Home />
						</PrivateRoute>
					}
				>
					<Route path="list-user" element={<ListUser />} />
				</Route>
				<Route path="/*" element={<NotFountPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
